
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healthkly3wojFxde0_45_Ancexw7lGnXcl1N8iCMXFYkEmz_GIMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_93Xc_UIsUlm7v_45JkqmiIg2VCe_45ld_4DcaWSlNpvn5ndsUMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as _91IATA_93kcAMF1Dc8SwlrU9b4THZlwDXpRuUKni_i3VtCz3Z_45cUMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue?macro=true";
import { default as _91IATA_93FkbkcZQkU5RYPKAJkIpwVMDoaZW4gKFsTHyrk7B_45PWUMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue?macro=true";
import { default as _91IATA_93vekrAX53xD5UIjlOcJo4uyB20JFI0nQaYoQ85Zl__45_wMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue?macro=true";
import { default as _91_46_46_46slug_93tJmo1uhKnxSNB7UqAAHCY73g_45zc8K4ewdD_GSsKzhR4Meta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue?macro=true";
import { default as _91IATA_93Szvt5DLalGFEzHy1Iqly9sUa5I7JEMiMv6gtoK0HCgYMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue?macro=true";
import { default as _91product_slug_93Kg1T04sU9bmpGWhR_TO8_lo4fw_45rvWHkMu8rpAAzSAoMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_93uz_KXRIoAdpw017RRmxTOrRCH0JRfeziITi5D0gpOZMMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_932mWjJyMsebuThl0lUBVr59y6rntB1MQYmS9aSWJg_458EMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_93W4ktAxOrASAyznwTb8bfPlGchct3CRsDEp0yJb9Ri9IMeta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue?macro=true";
import { default as component_45stubnupzZTSNuwrRoFqRilZlJqr_45M5MaueTrou87geMcpQ0Meta } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnupzZTSNuwrRoFqRilZlJqr_45M5MaueTrou87geMcpQ0 } from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "port-parking-IATA___en",
    path: "/port-parking/:IATA()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue")
  },
  {
    name: "airport-lounges-IATA___en",
    path: "/airport-lounges/:IATA()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue")
  },
  {
    name: "airport-parking-IATA___en",
    path: "/airport-parking/:IATA()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue")
  },
  {
    name: "subdomain-business-slug___en",
    path: "/subdomain/business/:slug(.*)*",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue")
  },
  {
    name: "airport-hotels-with-parking-IATA___en",
    path: "/airport-hotels-with-parking/:IATA()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue")
  },
  {
    name: "port-parking-location-product_slug___en",
    path: "/port-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-lounges-location-product_slug___en",
    path: "/airport-lounges/:location()/:product_slug()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue")
  },
  {
    name: "airport-parking-location-product_slug___en",
    path: "/airport-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-hotels-with-parking-location-product_slug___en",
    path: "/airport-hotels-with-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue")
  },
  {
    name: component_45stubnupzZTSNuwrRoFqRilZlJqr_45M5MaueTrou87geMcpQ0Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubnupzZTSNuwrRoFqRilZlJqr_45M5MaueTrou87geMcpQ0
  }
]