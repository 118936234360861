import revive_payload_client_XMg21bqoedP_Cq1Vh2qvE9860zYWKdKBtv_2lCNwz0Y from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead__I0ie04boGiX_OsktEeSYn__PYn656Fpe8YGHWOZp6Q from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_48lmVP0NXShKxB1XxKs9_p1u2kG3dH6Q1FbGtD_sPIU from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2_DnNXDp6S4_GwmsMqA0T3f2EDfpGZ8EqHmTlmhU3Pg from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.4_@types+node@22.13.14_jiti@2.4.2_terse_5af998e98ddc5b6103d688e0e80751d1/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kq215iNPbmWUP8AmH8M5hv_5_h_pjhYBzHyOB9fVKHo from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FyalVGcDm7_ZkoqjT3UpOnwMUGf3oT5t8adI4dv5OJ0 from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__atlEcQWBY17j6_7S56CDx2T_KwWK5VyRYfdoyO3zc0 from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9frnzU55wjgiqjO8UEfm_vGnDhlPWkSDFZk4uwXcZ6c from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/.nuxt/components.plugin.mjs";
import prefetch_client_JjnYxABbVOYFnaamfUkbBgi5cmf7HjCPIfTHYCWTyZY from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@8.57.1_ioredis@_3c23a5a69b47bee50f335e274e5b7fc6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_FYbY_wqa3jFEyo1uxKh_oKJsIAXs5T6QNycaAzqPFh4 from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.1_@parcel+watcher@2.5.1_@type_020a9ff6e565448143a0b774ceaddb08/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_Zjk50jiXNzNTuFRhBWT1_SzfQKiMGQWK8xXvywGDKK4 from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.4_@types+node@22.13.14_jiti@2.4.2_terser@5.39_920f675c80adba999b4043f6f0386cd5/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.8.2_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8 from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue_6cc402edc64b5b431717c79808b7ec48/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_KhRnvPH_3WLgMp1GPtWVqlocqCV3hc9e3lBr4gyix3M from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_SCn1vGYeTulmveUeb7VEcX8l5nztxYToi243T_Aum6k from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EfzintqZOG70gQ9Mn9sGWHXYirS__so2ktUr5HWyaQE from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
import ssg_detect_eMfC1h_kLw_IxnCUiKBAQvWIeptKW5LVgUxqpAONedM from "/codebuild/output/src3181699504/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_XMg21bqoedP_Cq1Vh2qvE9860zYWKdKBtv_2lCNwz0Y,
  unhead__I0ie04boGiX_OsktEeSYn__PYn656Fpe8YGHWOZp6Q,
  router_48lmVP0NXShKxB1XxKs9_p1u2kG3dH6Q1FbGtD_sPIU,
  _0_siteConfig_2_DnNXDp6S4_GwmsMqA0T3f2EDfpGZ8EqHmTlmhU3Pg,
  payload_client_kq215iNPbmWUP8AmH8M5hv_5_h_pjhYBzHyOB9fVKHo,
  navigation_repaint_client_FyalVGcDm7_ZkoqjT3UpOnwMUGf3oT5t8adI4dv5OJ0,
  check_outdated_build_client__atlEcQWBY17j6_7S56CDx2T_KwWK5VyRYfdoyO3zc0,
  chunk_reload_client_9frnzU55wjgiqjO8UEfm_vGnDhlPWkSDFZk4uwXcZ6c,
  plugin_vue3_yyPFIJnMrljikG8d6c41JgG8rbOVUrEPbL80wKE56OI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_JjnYxABbVOYFnaamfUkbBgi5cmf7HjCPIfTHYCWTyZY,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  plugin_FYbY_wqa3jFEyo1uxKh_oKJsIAXs5T6QNycaAzqPFh4,
  plugin_Zjk50jiXNzNTuFRhBWT1_SzfQKiMGQWK8xXvywGDKK4,
  plugin_XuzOnwN_hojXs6kdzcQF5Yhb0nwhJqWKg11Hm91NiQg,
  plugin_RJSKME5Jr0msl7PpfWTXDSD62ktnDsJL_SL5EILduL8,
  switch_locale_path_ssr_KhRnvPH_3WLgMp1GPtWVqlocqCV3hc9e3lBr4gyix3M,
  route_locale_detect_SCn1vGYeTulmveUeb7VEcX8l5nztxYToi243T_Aum6k,
  i18n_EfzintqZOG70gQ9Mn9sGWHXYirS__so2ktUr5HWyaQE,
  base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE,
  ssg_detect_eMfC1h_kLw_IxnCUiKBAQvWIeptKW5LVgUxqpAONedM
]